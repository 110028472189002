import React from 'react'
import PropTypes from 'prop-types'
import Layout from './image-banner.styles'
import { Parallax } from 'react-parallax'
import styled from 'styled-components'

const StyledParallax = styled(Parallax)`
  &:after {
    background: rgba(25, 28, 38, 0.7);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

const ImageBanner = ({ isFeatureHero = false, imageSrc, title, subtitle }) => {
  return (
    <StyledParallax strength={300} bgImageAlt='banner image' bgImage={imageSrc}>
      <Layout>
        <Layout.content>
          <Layout.title isFeatureHero={isFeatureHero}>{title}</Layout.title>
          <Layout.subtitle>{subtitle}</Layout.subtitle>
        </Layout.content>
      </Layout>
    </StyledParallax>
  )
}

ImageBanner.propTypes = {
  isFeatureHero: PropTypes.bool,
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ImageBanner
