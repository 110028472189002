import React from 'react'
import Container from './info-icon.styles'

const InfoIcon = (props) => {
  return (
    <Container>
      <Container.iconBorder>
        <Container.icon>{props.icon}</Container.icon>
      </Container.iconBorder>
      <Container.text>
        <Container.title>{props.title}</Container.title>
        <Container.subtitle>{props.subtitle}</Container.subtitle>
      </Container.text>
    </Container>
  )
}

export default InfoIcon
