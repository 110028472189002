import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import theme from '../../helpers/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 360px;
  width: 280px;

  :hover > :first-child {
    background-color: ${theme.colors.clearturqoise};
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    border-color: transparent;
    transition: all 0.2s;
  }
`

const IconBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${theme.colors.lightgray};
  border-radius: 50%;
  height: 150px;
  width: 150px;
`

const Image = styled(Icon)`
  font-size: ${theme.font['7xl']} !important;
  color: ${theme.colors.turqoise};
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
  margin-top: ${theme.space[10]}px;
`

const Title = styled.span`
  color: ${theme.colors.blackhighlight};
  font-size: ${theme.font['2xl']};
  margin-bottom: ${theme.space[5]}px;
`

const Subtitle = styled.span`
  color: ${theme.colors.gray};
  font-size: ${theme.font.base};
`

Container.iconBorder = IconBorder
Container.icon = Image
Container.text = Text
Container.title = Title
Container.subtitle = Subtitle
export default Container
