import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const Layout = styled.div`
  height: 350px;
`

Layout.content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  padding: 0 ${theme.space[4]}px;
`

Layout.title = styled.div`
  color: ${theme.colors.font.white};
  text-align: center;
  padding: 0 ${theme.space[2]}px;
  font-size: ${(props) =>
    props.isFeatureHero ? theme.font['4xl'] : theme.font['3xl']};
  ${media('md')} {
    font-size: ${(props) =>
      props.isFeatureHero ? theme.font['5xl'] : theme.font['4xl']};
  }
  z-index: 100;
`
Layout.subtitle = styled.div`
  color: ${theme.colors.font.white};
  font-size: ${theme.font['base']};
  margin-top: ${theme.space[3]}px;
  padding: 0 ${theme.space[4]}px;
  text-align: center;
  ${media('md')} {
    font-size: ${theme.font['2xl']};
  }
  z-index: 100;
`

export default Layout
